<template>
    <div class="mt-3 px-xl-5">

        <b-row class="justify-content-end mb-3">
            <b-col v-if="allowInvite" class="text-end">
                <b-button class="main-btn" @click="goToSendInvitation">
                    <b-icon-person-plus-fill scale="1.2" />
                </b-button>
            </b-col>
            <b-col xl="2" md="2" cols="2">
                <select class="form-select form-select-sm rounded-0 mt-1" v-model="status" @change="filterTable">
                    <option :value="null" selected hidden>Estado</option>
                    <option :value="null"></option>
                    <option :value="true">Activo</option>
                    <option :value="false">Inactivo</option>
                </select>
            </b-col>
            <b-col xl="3" md="6">
                <b-input-group class="mt-1">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
                        @keydown.enter="filterTable" />
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm" @click="filterTable">
                            <b-icon-search />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table ref="residentAgentsTable" stacked="md" small hover select-mode="single" show-empty
                        empty-text="No hay entidades que mostrar" tbody-tr-class="is-pointer" :filter="filter"
                        :busy.sync="isBusy" :fields="fields" :items="retrieveData" :current-page="currentPage"
                        :per-page="take" @filtered="onFiltered" @row-clicked="goResidentAgent">
                        <template #cell(isAdmin)="data">
                            <div>
                                {{ data.item.isAdmin ? "Sí" : "No" }}
                            </div>
                        </template>
                        <template #cell(isEnabled)="data">
                            <div>
                                {{ data.item.isEnabled ? "Sí" : "No" }}
                            </div>
                        </template>
                        <template #cell(disable)="data">
                            <div>
                                <b-button :disabled="!allowDisable(data.item)"
                                    @click="openDisableResidentAgent(data.item)"
                                    :variant="data.item.isEnabled ? 'success' : 'danger'">
                                    <b-icon icon="x-circle" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                    class="my-0 justify-content-end">
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
        <DisableResidentAgentModal :isOpen="openedDisable" :id="residentAgentId" @close="closeDisable"
            @disabled="refreshTable" />
    </div>
</template>

<script>
import moment from "moment";

import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/services/api";

import DisableResidentAgentModal from "./DisableResidentAgentModal.vue";

export default {
    name: "resident-agent-list",
    components: {
        DisableResidentAgentModal,
    },
    data() {
        return {
            fields: [
                { key: "name", label: "Nombre" },
                { key: "identification", label: "Identificación" },
                {
                    key: "identificationType",
                    label: "Tipo identificación",
                },
                { key: "email", label: "Email" },
                { key: "nationality", label: "Nacionalidad" },
                { key: "residentAgentType", label: "Tipo de Rol" },
                { key: "lawFirmName", label: "Firma" },
                { key: "cur", label: "CUR" },
                { key: "isAdmin", label: "Administrador" },
                { key: "isEnabled", label: "Activo" },
                { key: "disable", label: "" },
            ],
            search: "",
            status: null,
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,

            residentAgentId: null,
            openedDisable: false,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceCreateRA",
            "allowResidentAgentCreateRoles",]),
        ...mapGetters("application", ["isSuperUser"]),
        ...mapGetters("oidcStore", ["oidcUser", "oidcIsAuthenticated"]),
        allowInvite() {
            return (
                this.allowSuperintendenceCreateRA ||
                this.allowResidentAgentCreateRoles
            );
        },
    },
    methods: {
        allowDisable(residentAgent) {
            return residentAgent.isEnabled && this.isSuperUser;
        },
        oidcemail() {
            return `${this.oidcUser.email}`;
        },
        filterTable() {
            this.currentPage = 1;
            this.filter = this.search;
            this.refreshTable();
        },
        onFiltered(filteredItems) {
            console.log(filteredItems);
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                    status: this.status
                };
                let response = await RepositoryFactory.residentAgent.getPaged(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.birthDate = this.parseDate(item.birthDate);
                    item.residentAgentType = this.getResidentAgentString(
                        item.residentAgentType
                    );
                    item.identificationType = this.getIdentificationTypeString(
                        item.identificationType
                    );
                    item.lawFirmName = item.lawFirmName.toUpperCase()
                }

                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        getResidentAgentString(type) {
            switch (type) {
                case 0:
                    return "Abogado";
                case 1:
                    return "Asistente";
                case 2:
                    return "Interno";
                case 3:
                    return "Oficial de cumplimiento";
            }
            return "";
        },
        getIdentificationTypeString(type) {
            switch (type) {
                case 0:
                    return this.$t("general.cedula");
                case 1:
                    return this.$t("general.passport");
            }
            return "-";
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        goToSendInvitation() {
            this.$router.push({
                name: "send-resident-agent-invitation",
            });
        },
        openDisableResidentAgent(residentAgent) {
            if (residentAgent.email !== this.oidcemail()) {
                this.openedDisable = true;
                this.residentAgentId = residentAgent.id;
            }
        },
        closeDisable() {
            this.openedDisable = false;
        },
        refreshTable() {
            this.$refs.residentAgentsTable.refresh();
        },
        goResidentAgent(item) {
            if (item.isEnabled) {
                this.$router.push({
                    name: "resident-agent",
                    params: { id: item.id },
                });
            }
        },
    },
};
</script>