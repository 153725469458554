<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">
        Listado de Motivos para deshabilitar entidades legales
      </h6>
    </b-row>
    <b-row class="justify-content-end mb-3">
      <b-col class="text-end">
        <b-button class="main-btn" @click="addModal">
          <b-icon-plus />
        </b-button>
      </b-col>
      <b-col xl="3" md="6">
        <b-input-group>
          <b-form-input
            placeholder="Buscar"
            class="rounded-0"
            size="sm"
            v-model="search"
            @keydown.enter="filterTable"
          ></b-form-input>
          <b-input-group-append>
            <b-button class="main-btn" size="sm" @click="filterTable">
              <b-icon-search></b-icon-search>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table
            ref="rraTable"
            stacked="md"
            small
            hover
            select-mode="single"
            selectable
            show-empty
            empty-text="No hay registros que mostrar"
            :filter="filter"
            :busy.sync="isBusy"
            :fields="fields"
            :items="retrieveData"
            :current-page="currentPage"
            :per-page="take"
            @filtered="onFiltered"
          >
            <template #cell(createdAt)="data">
              {{ formattedDate(data.item.createdAt) }}
            </template>

            <template #cell(isActive)="data">
              <span @click="openUpdate(data.item)">
                <b-icon
                  icon="toggle-on"
                  scale="1.5"
                  v-b-tooltip.hover
                  title="Cambiar estado"
                  variant="success"
                  v-if="data.item.isActive"
                />
                <b-icon
                  icon="toggle-off"
                  scale="1.5"
                  v-b-tooltip.hover
                  title="Cambiar estado"
                  variant="danger"
                  v-else
                />
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      v-model="showModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      body-bg-variant="light"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <b-row class="p-3">
          <b-form-group class="mt-2" v-if="id == null">
            <label for="reason" class="label-title">Motivo:</label>

            <b-form-textarea
              v-model="posts.reason"
              size="sm"
              rows="4"
              required
              no-resize
              class="rounded-0"
            />
          </b-form-group>
          <b-form-group class="mt-2" v-else>
            <label for="reason" class="label-title">{{ posts.reason }}</label>
          </b-form-group>

          <b-form-group class="mt-3">
            <b-form-checkbox v-model="posts.isActive" switch>
              <span class="form-title"> Activo </span>
            </b-form-checkbox>
          </b-form-group>
        </b-row>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay
              :show="sending"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                class="main-btn me-3"
                size="sm"
                @click="save"
                :disabled="sending"
                >Guardar</b-button
              >
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeModal"
              >Cancelar</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "reason-disable-legal-entity",
  data() {
    return {
      fields: [
        { key: "isActive", label: "Activo", class: "text-center" },
        { key: "reason", label: "Motivo" },
        { key: "createdAt", label: "Creado" },
        { key: "createSuperintendence", label: "Por" },
      ],
      search: "",
      filter: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
      showModal: false,
      posts: {
        reason: null,
        isActive: false,
      },
      sending: false,
      id: null,
    };
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    filterTable() {
      this.filter = this.search;
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
    },
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: 10,
          search: context.filter,
        };
        let response =
          await RepositoryFactory.legalEntity.getReasonDisablePaged(payload);
        this.rows = response.data.total;
        let items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    reset() {
      this.id = null;
      this.posts = {
        reason: null,
        isActive: false,
      };
    },
    addModal() {
      this.showModal = true;
      this.reset();
    },
    closeModal() {
      this.showModal = false;
      this.reset();
    },
    async save() {
      if (this.posts.reason === null || this.posts.reason === "") {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Debe escribir un motivo.",
        });
        return;
      }

      try {
        this.sending = true;
        var payload = {
          reasonDisable: {
            reason: this.posts.reason,
            isActive: this.posts.isActive,
          },
        };

        if (this.id != null) {
          payload.id = this.id;
          await RepositoryFactory.legalEntity.updateReasonDisable(payload);
        } else {
          await RepositoryFactory.legalEntity.addReasonDisable(payload);
        }

        this.refreshTable();
        this.closeModal();
      } catch (error) {
        window.console.log(error);
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Error al guardar motivo.",
        });
      } finally {
        this.sending = false;
      }
    },
    openUpdate(item) {
      this.id = item.id;
      this.posts = {
        reason: item.reason,
        isActive: item.isActive,
      };
      this.showModal = true;
    },
    refreshTable() {
      this.$refs.rraTable.refresh();
    },
    formattedDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
  },
};
</script>

<style scoped>
.modal-custom-title {
  position: relative;
  width: 100%;
}

.close-modal-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.label-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 3px;
}

.reasonDisable-type-0 {
  text-transform: uppercase;
  color: green;
}
.reasonDisable-type-1 {
  text-transform: uppercase;
  color: red;
}
</style>
