<template>
    <div class="mt-3 px-xl-5">
        <b-row class="justify-content-end mb-3">
            <b-col v-if="allowInvite" class="text-end">
                <b-button class="main-btn" @click="goToSendInvitation">
                    <b-icon-person-plus-fill scale="1.2" />
                </b-button>
            </b-col>
            <b-col xl="3" md="6">
                <b-input-group class="mt-1">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
                        @keydown.enter="filterTable" />
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm" @click="filterTable">
                            <b-icon-search />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table ref="residentAgentsTable" stacked="md" small hover select-mode="single" show-empty
                        empty-text="No hay entidades que mostrar" tbody-tr-class="is-pointer" :filter="filter"
                        :busy.sync="isBusy" :fields="fields" :items="retrieveData" :current-page="currentPage"
                        :per-page="take" @filtered="onFiltered">
                        <template #cell(used)="data">
                            <div>
                                {{ data.item.used ? "Sí" : "No" }}
                            </div>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                    class="my-0 justify-content-end">
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";

import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "invitation-agent-list",
    data() {
        return {
            fields: [
                { key: "email", label: "Email" },
                { key: "creationDate", label: "Fecha de Invitación" },
                { key: "residentAgentType", label: "Tipo de Rol" },
                { key: "used", label: "Usado" },
                { key: "useDate", label: "Fecha de Uso" },
                { key: "creationDateLegalEntity", label: "Fecha de Primara Carga" },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceCreateRA",
            "allowResidentAgentCreateRoles",]),
        ...mapGetters("application", ["isSuperUser"]),
        ...mapGetters("oidcStore", ["oidcUser", "oidcIsAuthenticated"]),
        allowInvite() {
            return (
                this.allowSuperintendenceCreateRA ||
                this.allowResidentAgentCreateRoles
            );
        },
    },
    methods: {
        oidcemail() {
            return `${this.oidcUser.email}`;
        },
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                };
                let response = await RepositoryFactory.residentAgent.getPagedInvitations(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.creationDate = this.parseDate(item.creationDate);
                    if (item.useDate) {
                        item.useDate = this.parseDate(item.useDate);
                    }
                    if (item.creationDateLegalEntity) {
                        item.creationDateLegalEntity = this.parseDate(item.creationDateLegalEntity);
                    }
                    item.residentAgentType = this.getResidentAgentString(
                        item.residentAgentType
                    );
                }

                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        getResidentAgentString(type) {
            switch (type) {
                case 0:
                    return "Abogado";
                case 1:
                    return "Asistente";
                case 2:
                    return "Interno";
                case 3:
                    return "Oficial de cumplimiento";
            }
            return "";
        },
        getIdentificationTypeString(type) {
            switch (type) {
                case 0:
                    return this.$t("general.cedula");
                case 1:
                    return this.$t("general.passport");
            }
            return "-";
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY hh:mm A");
        },
        goToSendInvitation() {
            this.$router.push({
                name: "send-resident-agent-invitation",
            });
        },
        refreshTable() {
            this.$refs.residentAgentsTable.refresh();
        },
    },
};
</script>