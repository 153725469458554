<template>
  <b-modal v-model="isOpen" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
    header-bg-variant="light" centered no-close-on-backdrop hide-footer>
    <template #modal-header>
      <div class="modal-custom-title">
        <h5 class="modal-title">
          {{ $t("disable-resident-agent-modal.title") }}
        </h5>
        <b-button size="sm" class="close-modal-button" variant="light" @click="close">
          <b-icon-x scale="1.5"></b-icon-x>
        </b-button>
      </div>
    </template>
    <b-row v-if="isError" class="p-2">
      <b-col class="text-center">
        <b-icon-x-circle scale="2" variant="danger" />
        <h6 class="mt-3">
          Se ha producido un error intentando deshabilitar el agente residente,
          recargue la ventana y vuelva a intentarlo.
        </h6>
        <b-button class="main-btn mt-2" @click="close">Aceptar</b-button>
      </b-col>
    </b-row>
    <b-row v-if="isSuccess" class="p-2">
      <b-col class="text-center">
        <b-icon-shield-check scale="2" variant="success" />
        <h6 class="mt-3">
          Se ha deshabilitado correctamente el agente residente
        </h6>
        <b-button class="main-btn mt-2" @click="close">Aceptar</b-button>
      </b-col>
    </b-row>
    <b-row v-if="!isError && !isSuccess">
      <div v-if="lawFirm && lawFirm.totalResidentAgents == 1">
        <b-row class="mt-3">
          <b-col md="4">
            <label class="details-title">Seleccione motivo*</label>
          </b-col>
          <b-col md="8">
            <div class="position-relative">
              <b-button class="w-100 collapse-reason" v-b-toggle.collapse-3>
                <b-row align-h="between">
                  <b-col class="text-start"> Seleccione motivo(s) </b-col>
                  <b-col class="text-end">
                    <b-icon-chevron-down />
                  </b-col>
                </b-row>
              </b-button>
              <b-collapse invisible id="collapse-3" class="position-absolute" style="z-index: 1000">
                <b-card>
                  <b-form-checkbox-group class="checkbox-container" v-model="selected">
                    <b-row>
                      <b-col md="12" v-for="item in reasons" :key="item.key">
                        <b-form-checkbox :value="item.id">
                          <span class="checkbox-text">
                            {{ item.reason }}
                          </span>
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-form-checkbox-group>
                </b-card>
              </b-collapse>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="4">
            <label class="details-title">Adjuntar documento*</label>
          </b-col>
          <b-col md="8">
            <div class="file-input">
              <label for="image" class="btn main-btn btn-secondary">
                <b-icon-file-earmark-arrow-up class="me-1" />
                Cargar archivos
              </label>
              <input id="image" type="file" accept="*" multiple @change="onInputChange" />
            </div>

            <div class="previews mt-1 mb-4">
              <div v-for="(file, index) in selectedFiles" :key="index" class="preview">
                <a :href="file.preview" target="_blank" class="me-2">{{
    file.name
  }}</a>
                <b-icon-x-circle style="cursor: pointer" @click="removeFile(index)" variant="danger" v-b-tooltip.hover
                  title="Quitar" />
              </div>
            </div>
          </b-col>
        </b-row>

        <b-col md="12">
          <b-form-group>
            <label class="details-title">Observaciones (Opcional)</label>
            <b-form-textarea v-model="observation" size="sm" rows="4" no-resize class="rounded-0" />
          </b-form-group>
        </b-col>
      </div>

      <b-col class="text-center">
        <h6 class="mt-3" v-if="lawFirm && lawFirm.totalResidentAgents == 1">
          Está a punto de deshabilitar el último usuario de la firma
          <strong>{{ lawFirm.name }}</strong>, esto significa que van a deshabilitar todas sus sociedades. ¿Está seguro
          de
          que
          desea proceder?
        </h6>
        <h6 class="mt-3" v-else>
          Se va a deshabilitar este agente residente, esto impedirá al usuario
          acceder al sistema.
        </h6>
        <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="danger" class="d-inline-block">
          <b-button class="my-4" variant="danger" @click="disableResidentAgent">Deshabilitar</b-button>
        </b-overlay>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";

export default {
  name: "disable-resident-agent-modal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      sending: false,
      isError: false,
      isSuccess: false,
      reasons: [],
      selected: [],
      selectedFiles: [],
      observation: null,
      lawFirm: null,
    };
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.isError = false;
        this.isSuccess = false;
        this.reset();
        this.getAvailable();
      }
    },
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    reset() {
      this.selected = [];
      this.selectedFiles = [];
      this.observation = null;
      this.lawFirm = null;
    },
    async getAvailable() {
      this.loading = true;
      try {
        let response = await RepositoryFactory.residentAgent.available({
          id: this.id,
        });
        this.lawFirm = response.data;
      } catch (error) {
        window.console.log(error);
      }
      this.loading = false;
    },
    async getReasons() {
      this.loading = true;
      try {
        let response =
          await RepositoryFactory.residentAgent.getReasonDisableAll();
        this.reasons = response.data;
      } catch (error) {
        window.console.log(error);
      }
      this.loading = false;
    },
    async disableResidentAgent() {
      if (
        this.lawFirm?.totalResidentAgents == 1 &&
        this.selected.length === 0
      ) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Selecciona al menos un motivo.",
        });
        return;
      }

      if (
        this.lawFirm?.totalResidentAgents == 1 &&
        this.selectedFiles.length === 0
      ) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Adjunta al menos un archivo.",
        });
        return;
      }

      try {
        this.sending = true;
        let formData = new FormData();

        formData.append("id", this.id);
        formData.append("observation", this.observation);

        for (const item of this.selected) {
          formData.append("reasons", item);
        }

        for (const file of this.selectedFiles) {
          formData.append("files", file);
        }

        console.log(formData);

        await RepositoryFactory.residentAgent.disable(formData);
        this.$emit("disabled");
        this.isSuccess = true;
      } catch (error) {
        window.console.log(error.response);
        this.isError = true;
      } finally {
        this.sending = false;
      }
    },
    close() {
      this.$emit("close");
    },
    hideError() {
      this.isError = false;
    },
    onInputChange(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.selectedFiles.push(e.target.files[i]);
      }
      this.handleFileChange();
    },
    handleFileChange() {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        file.preview = URL.createObjectURL(file);
      }
    },
    removeFile(index) {
      URL.revokeObjectURL(this.selectedFiles[index].preview);
      this.selectedFiles.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.modal-custom-title {
  position: relative;
  width: 100%;
}

.close-modal-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.collapse-reason {
  background-color: white !important;
  color: black !important;
  border-color: #406892 !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 10;
  left: 0;
  right: 0;
}
</style>
