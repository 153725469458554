<template>
    <div class="mt-3 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            {{ $t("navbar.resident-agents") }}
        </h6>
        <b-row class="pb-5">
            <h6>
                <small class="text-muted">
                    Este listado contiene los roles que ha asignado el Agente
                    Residente dentro de su firma de abogados
                </small>
            </h6>
        </b-row>
        <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
            <b-tab :title="$t('roles.title')" title-item-class="dash-tab" @click="changeTab(0)">
                <ResidentAgentList />
            </b-tab>
            <b-tab v-if="allowCurManagement" :title="$t('roles.invitations')" title-item-class="dash-tab"
                @click="changeTab(1)">
                <InvitationsList />
            </b-tab>
            <b-tab v-if="allowCriteria" title="Motivos para deshabilitar AR" title-item-class="dash-tab" @click="changeTab(2)">
                <ReasonDisableResidentAgent />
            </b-tab>
            <b-tab v-if="allowCriteria" title="Motivos para deshabilitar Entidad Legal" title-item-class="dash-tab" @click="changeTab(3)">
                <ReasonDisableLegalEntity />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import InvitationsList from "./InvitationsList.vue";
import ReasonDisableLegalEntity from "./ReasonDisableLegalEntity.vue";
import ReasonDisableResidentAgent from "./ReasonDisableResidentAgent.vue";
import ResidentAgentList from "./ResidentAgentList.vue";

export default {
    name: "law-firms",
    components: {
        ResidentAgentList,
        InvitationsList,
        ReasonDisableResidentAgent,
        ReasonDisableLegalEntity
    },
    created() {
        if (this.$route.params.tab) {
            this.tabIndex = this.$route.params.tab;
        }
    },
    data() {
        return {
            tabIndex: 0,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceCurManagement",
            "allowSuperintendenceConfigureCriteria",
        ]),
        allowCurManagement() {
            return this.allowSuperintendenceCurManagement;
        },
        allowCriteria() {
            return this.allowSuperintendenceConfigureCriteria;
        },
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index;
        },
    },
};
</script>
